const {
  API_CHANNEL_NAME: apiChannelName,
  API_CHANNEL_THUMBNAIL: apiChannelThumbnail,
  APP_VERSION: appVersion,
  AZURE_APP_ID: azureAppId,
  BRAND_NAME: brandName,
  CHATWOOT_INBOX_TOKEN: chatwootInboxToken,
  CSML_EDITOR_HOST: csmlEditorHost,
  CREATE_NEW_ACCOUNT_FROM_DASHBOARD: createNewAccountFromDashboard,
  DIRECT_UPLOADS_ENABLED: directUploadsEnabled,
  DISPLAY_MANIFEST: displayManifest,
  GIT_SHA: gitSha,
  HCAPTCHA_SITE_KEY: hCaptchaSiteKey,
  INSTALLATION_NAME: installationName,
  LOGO_THUMBNAIL: logoThumbnail,
  LOGO: logo,
  LOGO_DARK: logoDark,
  PRIVACY_URL: privacyURL,
  TERMS_URL: termsURL,
  QUEPASA_HOST: quepasaHost,
  N8N_HOST: n8nHost,
  WHATSAPP_BRIDGE_HOST: whatsappBridgeHost,
  TYPEBOT_VIEWER_HOST: typebotViewerHost,
  TYPEBOT_BUILDER_HOST: typebotBuilderHost,
  WHATSAPP_ENABLE_GROUP: whatsAppEnableGroup,
  WABA_EMBEDDED_SIGNUP: wabaEmbeddedSignup,
  WHATSAPP_EMBEDDED_LICENSE_KEY: whatsappEmbeddedLicenseKey,
  WHATSAPP_EMBEDDED_FACEBOOK_APP_ID: whatsappEmbeddedFacebookAppId,
  WHATSAPP_EMBEDDED_FACEBOOK_CONFIG_ID: whatsappEmbeddedFacebookConfigId,
  WHATSAPP_EMBEDDED_FACEBOOK_TOKEN: whatsappEmbeddedFacebookToken,
  WIDGET_BRAND_URL: widgetBrandURL,
  DISABLE_USER_PROFILE_UPDATE: disableUserProfileUpdate,
  DEPLOYMENT_ENV: deploymentEnv,
} = window.globalConfig || {};

const state = {
  apiChannelName,
  apiChannelThumbnail,
  appVersion,
  azureAppId,
  brandName,
  chatwootInboxToken,
  csmlEditorHost,
  deploymentEnv,
  createNewAccountFromDashboard,
  directUploadsEnabled: directUploadsEnabled === 'true',
  disableUserProfileUpdate: disableUserProfileUpdate === 'true',
  displayManifest,
  gitSha,
  hCaptchaSiteKey,
  installationName,
  quepasaHost,
  n8nHost,
  whatsappBridgeHost,
  typebotViewerHost,
  typebotBuilderHost,
  whatsAppEnableGroup,
  wabaEmbeddedSignup: wabaEmbeddedSignup || '',
  whatsappEmbeddedLicenseKey: whatsappEmbeddedLicenseKey || '',
  whatsappEmbeddedFacebookAppId: whatsappEmbeddedFacebookAppId || '',
  whatsappEmbeddedFacebookConfigId: whatsappEmbeddedFacebookConfigId || '',
  whatsappEmbeddedFacebookToken: whatsappEmbeddedFacebookToken || '',
  logo,
  logoDark,
  logoThumbnail,
  privacyURL,
  termsURL,
  widgetBrandURL,
};

export const getters = {
  get: $state => $state,
  isOnChatwootCloud: $state => $state.deploymentEnv === 'cloud',
  isACustomBrandedInstance: $state => $state.installationName !== 'Chatwoot',
  isAChatwootInstance: $state => $state.installationName === 'Chatwoot',
};

export const actions = {};

export const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
